import { Box } from '@fluentui/react-northstar'
import React from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import message_pb from '../../proto/message_pb'
import { consoleErrorWithAirbrake } from '../../utils'
import ChatItemAttachment from './ChatItemAttachment'
import { ChatItemImageAttachment } from './ChatItemImageAttachment'
import { isImage } from './common'
import { OneDriveData } from './types'

const ChatItemAttachments: React.FC<{
  attachments: Array<message_pb.ResponseAttachment.AsObject>
  userId?: string
  id: string
  shouldShowImagePreview?: boolean
}> = (props) => {
  const attachments = props.attachments
  const id = props.id
  return (
    <Box>
      {attachments.map((attachment, index) => (
        <LazyLoadComponent key={`c-${id}-${index}`}>
          <MapChatItemAttachment
            attachment={attachment}
            userId={props.userId}
            key={`c-${id}-${index}`}
            shouldShowImagePreview={props.shouldShowImagePreview}
          />
        </LazyLoadComponent>
      ))}
    </Box>
  )
}

const MapChatItemAttachment: React.FC<{
  attachment: message_pb.ResponseAttachment.AsObject
  userId?: string
  key: string
  shouldShowImagePreview?: boolean
}> = (props) => {
  const attachment = props.attachment
  switch (attachment.type) {
    case 'onedrive':
      try {
        const attachmentData = JSON.parse(attachment.data) as OneDriveData
        if (isImage(attachmentData.mimeType) && props.shouldShowImagePreview) {
          // 画像プレビュー機能ONの時に、添付画像のプレビューを表示します。
          return (
            <ChatItemImageAttachment
              driveId={attachmentData.driveId}
              userId={props.userId}
              itemId={attachmentData.itemId}
              webUrl={attachmentData.webUrl}
            />
          )
        }
        // 画像プレビュー機能OFFの時に、添付画像のプレビューを表示しません。代わりに、添付バーを表示して、クリックした後、シェアリンクへの遷移します。
        return <ChatItemAttachment attachmentData={attachmentData} />
      } catch (e) {
        consoleErrorWithAirbrake(
          `failed at parsing onedrive type data, error: ${e}, attachment data: ${attachment.data}`
        )
        return null
      }
    default:
      return null
  }
}

export default ChatItemAttachments
