import {
  DecoratorNode,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical'

export type ImagePayload = {
  altText?: string
  src: string
  driveId: string
  itemId: string
  webUrl: string
  name: string
  size: number
  key?: NodeKey
}

export type SerializedImageNode = Spread<
  {
    src: string
    altText?: string
    type: 'onedrive-image'
    driveId: string
    itemId: string
    version: 1
    dataWebUrl: string
    dataName: string
    size: number
  },
  SerializedLexicalNode
>

export class ImageNode extends DecoratorNode<JSX.Element> {
  __weburl: string
  __name: string
  __src: string
  __altText: string
  __driveId: string
  __itemId: string
  __size: number

  constructor(
    src: string,
    driveId: string,
    itemId: string,
    webUrl: string, // 画像のシェアリンク
    name: string, // 画像の名前
    size: number, // 画像のサイズ
    altText?: string,
    key?: NodeKey
  ) {
    super(key)
    this.__src = src
    this.__altText = altText ?? ''
    this.__driveId = driveId
    this.__itemId = itemId
    this.__weburl = webUrl
    this.__name = name
    this.__size = size
  }

  static getType(): 'onedrive-image' {
    return 'onedrive-image'
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(
      node.__src,
      node.__driveId,
      node.__itemId,
      node.__weburl,
      node.__name,
      node.__size,
      node.__altText
    )
  }

  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    return $createImageNode({
      src: serializedNode.src,
      altText: serializedNode.altText,
      driveId: serializedNode.driveId,
      itemId: serializedNode.itemId,
      webUrl: serializedNode.dataWebUrl,
      name: serializedNode.dataName,
      size: serializedNode.size,
    })
  }

  createDOM(): HTMLImageElement {
    const el = document.createElement('img')
    el.src = this.__src
    el.alt = this.__altText
    el.dataset.driveId = this.__driveId
    el.dataset.itemId = this.__itemId
    el.dataset.webUrl = this.__weburl
    el.dataset.name = this.__name
    el.dataset.size = `${this.__size}`
    el.style.maxWidth = '800px'
    el.style.maxHeight = '800px'

    return el
  }

  updateDOM(): boolean {
    return false
  }

  exportJSON(): SerializedImageNode {
    return {
      type: ImageNode.getType(),
      altText: this.__altText,
      src: this.__src,
      driveId: this.__driveId,
      itemId: this.__itemId,
      version: 1,
      dataWebUrl: this.__weburl,
      dataName: this.__name,
      size: this.__size,
    }
  }

  decorate(): JSX.Element {
    return (
      <img
        src={this.__src}
        alt={this.__altText}
        data-drive-id={this.__driveId}
        data-item-id={this.__itemId}
        data-web-url={this.__weburl}
        data-name={this.__name}
        data-size={this.__size}
      />
    )
  }

  setSrc(src: string) {
    this.__src = src
  }

  setItem(itemId: string, driveId: string, webUrl: string) {
    this.__driveId = driveId
    this.__itemId = itemId
    this.__weburl = webUrl
  }
}

export const $createImageNode = (payload: ImagePayload): ImageNode => {
  return new ImageNode(
    payload.src,
    payload.driveId,
    payload.itemId,
    payload.webUrl,
    payload.name,
    payload.size,
    payload.altText,
    payload.key
  )
}

export const $isImageNode = (
  node: LexicalNode | null | undefined
): node is ImageNode => {
  return node instanceof ImageNode
}
