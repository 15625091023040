import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { RootState } from '../../app/store'

// オペレーターが貼り付けた画像の情報
export interface PastedImageInfoState {
  id: number
  name?: string // 貼り付けた画像の名前
  itemId?: string // 貼り付けた画像のsharepointのitemId(graph api upload responseで取得)
  webUrl?: string // 貼り付け画像のsharepointのシェアリンク(graph api create sharelink responseで取得)
  uniqueId?: string // 貼り付けた画像のuniqueId(graph api upload responseで取得)
  size?: number // 貼り付けた画像のuniqueId(graph api upload responseで取得)
  driveId?: string // 貼り付けた画像sharepointのdriveID(graph api upload responseで取得)
}

export const imageInfoAdapter = createEntityAdapter<PastedImageInfoState>({
  selectId: (info) => info.id,
})

export const pastedImageInfoSlice = createSlice({
  name: 'imageInfo',
  initialState: imageInfoAdapter.getInitialState(),
  reducers: {
    addPastedImageInfo(state, action: PayloadAction<PastedImageInfoState>) {
      imageInfoAdapter.upsertOne(state, action.payload)
    },
    onPastedImageIn(state, action: PayloadAction<number>) {
      const id = action.payload
      imageInfoAdapter.addOne(state, { id })
    },
  },
})

export const { addPastedImageInfo, onPastedImageIn } =
  pastedImageInfoSlice.actions

export const pastedImageInfoSelectors = imageInfoAdapter.getSelectors(
  (state: RootState) => state.pastedImageInfo
)

export default pastedImageInfoSlice.reducer
