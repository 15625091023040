// source: proto/auth.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.AvailableFeatures', null, global);
goog.exportSymbol('proto.DeskAvailableFeatures', null, global);
goog.exportSymbol('proto.ExchangeTokenRequest', null, global);
goog.exportSymbol('proto.ExchangeTokenResponse', null, global);
goog.exportSymbol('proto.GraphError', null, global);
goog.exportSymbol('proto.GraphToken', null, global);
goog.exportSymbol('proto.RefreshTokenRequest', null, global);
goog.exportSymbol('proto.RefreshTokenResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ExchangeTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ExchangeTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ExchangeTokenResponse.displayName = 'proto.ExchangeTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GraphToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GraphToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GraphToken.displayName = 'proto.GraphToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AvailableFeatures = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AvailableFeatures, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AvailableFeatures.displayName = 'proto.AvailableFeatures';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeskAvailableFeatures = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeskAvailableFeatures, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeskAvailableFeatures.displayName = 'proto.DeskAvailableFeatures';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GraphError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GraphError.repeatedFields_, null);
};
goog.inherits(proto.GraphError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GraphError.displayName = 'proto.GraphError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ExchangeTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ExchangeTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ExchangeTokenRequest.displayName = 'proto.ExchangeTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RefreshTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RefreshTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RefreshTokenRequest.displayName = 'proto.RefreshTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RefreshTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RefreshTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RefreshTokenResponse.displayName = 'proto.RefreshTokenResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ExchangeTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ExchangeTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ExchangeTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExchangeTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    graphToken: (f = msg.getGraphToken()) && proto.GraphToken.toObject(includeInstance, f),
    graphError: (f = msg.getGraphError()) && proto.GraphError.toObject(includeInstance, f),
    availableFeatures: (f = msg.getAvailableFeatures()) && proto.AvailableFeatures.toObject(includeInstance, f),
    deskAvailableFeatures: (f = msg.getDeskAvailableFeatures()) && proto.DeskAvailableFeatures.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ExchangeTokenResponse}
 */
proto.ExchangeTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ExchangeTokenResponse;
  return proto.ExchangeTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ExchangeTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ExchangeTokenResponse}
 */
proto.ExchangeTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 2:
      var value = new proto.GraphToken;
      reader.readMessage(value,proto.GraphToken.deserializeBinaryFromReader);
      msg.setGraphToken(value);
      break;
    case 3:
      var value = new proto.GraphError;
      reader.readMessage(value,proto.GraphError.deserializeBinaryFromReader);
      msg.setGraphError(value);
      break;
    case 4:
      var value = new proto.AvailableFeatures;
      reader.readMessage(value,proto.AvailableFeatures.deserializeBinaryFromReader);
      msg.setAvailableFeatures(value);
      break;
    case 5:
      var value = new proto.DeskAvailableFeatures;
      reader.readMessage(value,proto.DeskAvailableFeatures.deserializeBinaryFromReader);
      msg.setDeskAvailableFeatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ExchangeTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ExchangeTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ExchangeTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExchangeTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGraphToken();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.GraphToken.serializeBinaryToWriter
    );
  }
  f = message.getGraphError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.GraphError.serializeBinaryToWriter
    );
  }
  f = message.getAvailableFeatures();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.AvailableFeatures.serializeBinaryToWriter
    );
  }
  f = message.getDeskAvailableFeatures();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.DeskAvailableFeatures.serializeBinaryToWriter
    );
  }
};


/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.ExchangeTokenResponse.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExchangeTokenResponse} returns this
 */
proto.ExchangeTokenResponse.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional GraphToken graph_token = 2;
 * @return {?proto.GraphToken}
 */
proto.ExchangeTokenResponse.prototype.getGraphToken = function() {
  return /** @type{?proto.GraphToken} */ (
    jspb.Message.getWrapperField(this, proto.GraphToken, 2));
};


/**
 * @param {?proto.GraphToken|undefined} value
 * @return {!proto.ExchangeTokenResponse} returns this
*/
proto.ExchangeTokenResponse.prototype.setGraphToken = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExchangeTokenResponse} returns this
 */
proto.ExchangeTokenResponse.prototype.clearGraphToken = function() {
  return this.setGraphToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExchangeTokenResponse.prototype.hasGraphToken = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GraphError graph_error = 3;
 * @return {?proto.GraphError}
 */
proto.ExchangeTokenResponse.prototype.getGraphError = function() {
  return /** @type{?proto.GraphError} */ (
    jspb.Message.getWrapperField(this, proto.GraphError, 3));
};


/**
 * @param {?proto.GraphError|undefined} value
 * @return {!proto.ExchangeTokenResponse} returns this
*/
proto.ExchangeTokenResponse.prototype.setGraphError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExchangeTokenResponse} returns this
 */
proto.ExchangeTokenResponse.prototype.clearGraphError = function() {
  return this.setGraphError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExchangeTokenResponse.prototype.hasGraphError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AvailableFeatures available_features = 4;
 * @return {?proto.AvailableFeatures}
 */
proto.ExchangeTokenResponse.prototype.getAvailableFeatures = function() {
  return /** @type{?proto.AvailableFeatures} */ (
    jspb.Message.getWrapperField(this, proto.AvailableFeatures, 4));
};


/**
 * @param {?proto.AvailableFeatures|undefined} value
 * @return {!proto.ExchangeTokenResponse} returns this
*/
proto.ExchangeTokenResponse.prototype.setAvailableFeatures = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExchangeTokenResponse} returns this
 */
proto.ExchangeTokenResponse.prototype.clearAvailableFeatures = function() {
  return this.setAvailableFeatures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExchangeTokenResponse.prototype.hasAvailableFeatures = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DeskAvailableFeatures desk_available_features = 5;
 * @return {?proto.DeskAvailableFeatures}
 */
proto.ExchangeTokenResponse.prototype.getDeskAvailableFeatures = function() {
  return /** @type{?proto.DeskAvailableFeatures} */ (
    jspb.Message.getWrapperField(this, proto.DeskAvailableFeatures, 5));
};


/**
 * @param {?proto.DeskAvailableFeatures|undefined} value
 * @return {!proto.ExchangeTokenResponse} returns this
*/
proto.ExchangeTokenResponse.prototype.setDeskAvailableFeatures = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExchangeTokenResponse} returns this
 */
proto.ExchangeTokenResponse.prototype.clearDeskAvailableFeatures = function() {
  return this.setDeskAvailableFeatures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExchangeTokenResponse.prototype.hasDeskAvailableFeatures = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GraphToken.prototype.toObject = function(opt_includeInstance) {
  return proto.GraphToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GraphToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GraphToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    tokenType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scope: jspb.Message.getFieldWithDefault(msg, 2, ""),
    expiresIn: jspb.Message.getFieldWithDefault(msg, 3, 0),
    extExpiresIn: jspb.Message.getFieldWithDefault(msg, 4, 0),
    accessToken: jspb.Message.getFieldWithDefault(msg, 5, ""),
    refreshToken: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GraphToken}
 */
proto.GraphToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GraphToken;
  return proto.GraphToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GraphToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GraphToken}
 */
proto.GraphToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTokenType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScope(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExpiresIn(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExtExpiresIn(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefreshToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GraphToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GraphToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GraphToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GraphToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTokenType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScope();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExpiresIn();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getExtExpiresIn();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRefreshToken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string token_type = 1;
 * @return {string}
 */
proto.GraphToken.prototype.getTokenType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GraphToken} returns this
 */
proto.GraphToken.prototype.setTokenType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string scope = 2;
 * @return {string}
 */
proto.GraphToken.prototype.getScope = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GraphToken} returns this
 */
proto.GraphToken.prototype.setScope = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 expires_in = 3;
 * @return {number}
 */
proto.GraphToken.prototype.getExpiresIn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.GraphToken} returns this
 */
proto.GraphToken.prototype.setExpiresIn = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 ext_expires_in = 4;
 * @return {number}
 */
proto.GraphToken.prototype.getExtExpiresIn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.GraphToken} returns this
 */
proto.GraphToken.prototype.setExtExpiresIn = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string access_token = 5;
 * @return {string}
 */
proto.GraphToken.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.GraphToken} returns this
 */
proto.GraphToken.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string refresh_token = 6;
 * @return {string}
 */
proto.GraphToken.prototype.getRefreshToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.GraphToken} returns this
 */
proto.GraphToken.prototype.setRefreshToken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AvailableFeatures.prototype.toObject = function(opt_includeInstance) {
  return proto.AvailableFeatures.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AvailableFeatures} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AvailableFeatures.toObject = function(includeInstance, msg) {
  var f, obj = {
    multiChannel: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    fileAttachment: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    tenantAppCsvExport: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    delegateMultiChannel: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    delegateTenantAppCsvExport: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    delegateFileReadWriteAll: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AvailableFeatures}
 */
proto.AvailableFeatures.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AvailableFeatures;
  return proto.AvailableFeatures.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AvailableFeatures} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AvailableFeatures}
 */
proto.AvailableFeatures.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMultiChannel(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFileAttachment(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTenantAppCsvExport(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDelegateMultiChannel(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDelegateTenantAppCsvExport(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDelegateFileReadWriteAll(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AvailableFeatures.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AvailableFeatures.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AvailableFeatures} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AvailableFeatures.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMultiChannel();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getFileAttachment();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getTenantAppCsvExport();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDelegateMultiChannel();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDelegateTenantAppCsvExport();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDelegateFileReadWriteAll();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional bool multi_channel = 1;
 * @return {boolean}
 */
proto.AvailableFeatures.prototype.getMultiChannel = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.AvailableFeatures} returns this
 */
proto.AvailableFeatures.prototype.setMultiChannel = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool file_attachment = 2;
 * @return {boolean}
 */
proto.AvailableFeatures.prototype.getFileAttachment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.AvailableFeatures} returns this
 */
proto.AvailableFeatures.prototype.setFileAttachment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool tenant_app_csv_export = 3;
 * @return {boolean}
 */
proto.AvailableFeatures.prototype.getTenantAppCsvExport = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.AvailableFeatures} returns this
 */
proto.AvailableFeatures.prototype.setTenantAppCsvExport = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool delegate_multi_channel = 4;
 * @return {boolean}
 */
proto.AvailableFeatures.prototype.getDelegateMultiChannel = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.AvailableFeatures} returns this
 */
proto.AvailableFeatures.prototype.setDelegateMultiChannel = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool delegate_tenant_app_csv_export = 5;
 * @return {boolean}
 */
proto.AvailableFeatures.prototype.getDelegateTenantAppCsvExport = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.AvailableFeatures} returns this
 */
proto.AvailableFeatures.prototype.setDelegateTenantAppCsvExport = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool delegate_file_read_write_all = 6;
 * @return {boolean}
 */
proto.AvailableFeatures.prototype.getDelegateFileReadWriteAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.AvailableFeatures} returns this
 */
proto.AvailableFeatures.prototype.setDelegateFileReadWriteAll = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeskAvailableFeatures.prototype.toObject = function(opt_includeInstance) {
  return proto.DeskAvailableFeatures.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeskAvailableFeatures} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeskAvailableFeatures.toObject = function(includeInstance, msg) {
  var f, obj = {
    draftFaq: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    faq: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeskAvailableFeatures}
 */
proto.DeskAvailableFeatures.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeskAvailableFeatures;
  return proto.DeskAvailableFeatures.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeskAvailableFeatures} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeskAvailableFeatures}
 */
proto.DeskAvailableFeatures.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDraftFaq(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFaq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeskAvailableFeatures.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeskAvailableFeatures.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeskAvailableFeatures} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeskAvailableFeatures.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDraftFaq();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getFaq();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool draft_faq = 1;
 * @return {boolean}
 */
proto.DeskAvailableFeatures.prototype.getDraftFaq = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeskAvailableFeatures} returns this
 */
proto.DeskAvailableFeatures.prototype.setDraftFaq = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool faq = 2;
 * @return {boolean}
 */
proto.DeskAvailableFeatures.prototype.getFaq = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeskAvailableFeatures} returns this
 */
proto.DeskAvailableFeatures.prototype.setFaq = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GraphError.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GraphError.prototype.toObject = function(opt_includeInstance) {
  return proto.GraphError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GraphError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GraphError.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: jspb.Message.getFieldWithDefault(msg, 1, ""),
    errorDescription: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errorCodesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    timestamp: jspb.Message.getFieldWithDefault(msg, 4, ""),
    traceId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    correlationId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    suberror: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GraphError}
 */
proto.GraphError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GraphError;
  return proto.GraphError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GraphError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GraphError}
 */
proto.GraphError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorDescription(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addErrorCodes(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimestamp(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTraceId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrelationId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuberror(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GraphError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GraphError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GraphError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GraphError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getErrorDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrorCodesList();
  if (f.length > 0) {
    writer.writePackedUint32(
      3,
      f
    );
  }
  f = message.getTimestamp();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTraceId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCorrelationId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSuberror();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string error = 1;
 * @return {string}
 */
proto.GraphError.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GraphError} returns this
 */
proto.GraphError.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string error_description = 2;
 * @return {string}
 */
proto.GraphError.prototype.getErrorDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GraphError} returns this
 */
proto.GraphError.prototype.setErrorDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated uint32 error_codes = 3;
 * @return {!Array<number>}
 */
proto.GraphError.prototype.getErrorCodesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.GraphError} returns this
 */
proto.GraphError.prototype.setErrorCodesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.GraphError} returns this
 */
proto.GraphError.prototype.addErrorCodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GraphError} returns this
 */
proto.GraphError.prototype.clearErrorCodesList = function() {
  return this.setErrorCodesList([]);
};


/**
 * optional string timestamp = 4;
 * @return {string}
 */
proto.GraphError.prototype.getTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.GraphError} returns this
 */
proto.GraphError.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string trace_id = 5;
 * @return {string}
 */
proto.GraphError.prototype.getTraceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.GraphError} returns this
 */
proto.GraphError.prototype.setTraceId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string correlation_id = 6;
 * @return {string}
 */
proto.GraphError.prototype.getCorrelationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.GraphError} returns this
 */
proto.GraphError.prototype.setCorrelationId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string suberror = 7;
 * @return {string}
 */
proto.GraphError.prototype.getSuberror = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.GraphError} returns this
 */
proto.GraphError.prototype.setSuberror = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ExchangeTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ExchangeTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ExchangeTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExchangeTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssoToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    graphScope: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    channelId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ExchangeTokenRequest}
 */
proto.ExchangeTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ExchangeTokenRequest;
  return proto.ExchangeTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ExchangeTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ExchangeTokenRequest}
 */
proto.ExchangeTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGraphScope(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ExchangeTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ExchangeTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ExchangeTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExchangeTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsoToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGraphScope();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string sso_token = 1;
 * @return {string}
 */
proto.ExchangeTokenRequest.prototype.getSsoToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExchangeTokenRequest} returns this
 */
proto.ExchangeTokenRequest.prototype.setSsoToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string graph_scope = 2;
 * @return {string}
 */
proto.ExchangeTokenRequest.prototype.getGraphScope = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExchangeTokenRequest} returns this
 */
proto.ExchangeTokenRequest.prototype.setGraphScope = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string group_id = 3;
 * @return {string}
 */
proto.ExchangeTokenRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExchangeTokenRequest} returns this
 */
proto.ExchangeTokenRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string channel_id = 4;
 * @return {string}
 */
proto.ExchangeTokenRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExchangeTokenRequest} returns this
 */
proto.ExchangeTokenRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RefreshTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.RefreshTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RefreshTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RefreshTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssoToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    refreshToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    graphScope: jspb.Message.getFieldWithDefault(msg, 3, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    channelId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    redirectUrl: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RefreshTokenRequest}
 */
proto.RefreshTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RefreshTokenRequest;
  return proto.RefreshTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RefreshTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RefreshTokenRequest}
 */
proto.RefreshTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefreshToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGraphScope(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RefreshTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RefreshTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RefreshTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RefreshTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsoToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRefreshToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGraphScope();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRedirectUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string sso_token = 1;
 * @return {string}
 */
proto.RefreshTokenRequest.prototype.getSsoToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RefreshTokenRequest} returns this
 */
proto.RefreshTokenRequest.prototype.setSsoToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string refresh_token = 2;
 * @return {string}
 */
proto.RefreshTokenRequest.prototype.getRefreshToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.RefreshTokenRequest} returns this
 */
proto.RefreshTokenRequest.prototype.setRefreshToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string graph_scope = 3;
 * @return {string}
 */
proto.RefreshTokenRequest.prototype.getGraphScope = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.RefreshTokenRequest} returns this
 */
proto.RefreshTokenRequest.prototype.setGraphScope = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string group_id = 4;
 * @return {string}
 */
proto.RefreshTokenRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.RefreshTokenRequest} returns this
 */
proto.RefreshTokenRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string channel_id = 5;
 * @return {string}
 */
proto.RefreshTokenRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.RefreshTokenRequest} returns this
 */
proto.RefreshTokenRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string redirect_url = 6;
 * @return {string}
 */
proto.RefreshTokenRequest.prototype.getRedirectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.RefreshTokenRequest} returns this
 */
proto.RefreshTokenRequest.prototype.setRedirectUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RefreshTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.RefreshTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RefreshTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RefreshTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    graphToken: (f = msg.getGraphToken()) && proto.GraphToken.toObject(includeInstance, f),
    graphError: (f = msg.getGraphError()) && proto.GraphError.toObject(includeInstance, f),
    availableFeatures: (f = msg.getAvailableFeatures()) && proto.AvailableFeatures.toObject(includeInstance, f),
    deskAvailableFeatures: (f = msg.getDeskAvailableFeatures()) && proto.DeskAvailableFeatures.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RefreshTokenResponse}
 */
proto.RefreshTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RefreshTokenResponse;
  return proto.RefreshTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RefreshTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RefreshTokenResponse}
 */
proto.RefreshTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 2:
      var value = new proto.GraphToken;
      reader.readMessage(value,proto.GraphToken.deserializeBinaryFromReader);
      msg.setGraphToken(value);
      break;
    case 3:
      var value = new proto.GraphError;
      reader.readMessage(value,proto.GraphError.deserializeBinaryFromReader);
      msg.setGraphError(value);
      break;
    case 4:
      var value = new proto.AvailableFeatures;
      reader.readMessage(value,proto.AvailableFeatures.deserializeBinaryFromReader);
      msg.setAvailableFeatures(value);
      break;
    case 5:
      var value = new proto.DeskAvailableFeatures;
      reader.readMessage(value,proto.DeskAvailableFeatures.deserializeBinaryFromReader);
      msg.setDeskAvailableFeatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RefreshTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RefreshTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RefreshTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RefreshTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGraphToken();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.GraphToken.serializeBinaryToWriter
    );
  }
  f = message.getGraphError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.GraphError.serializeBinaryToWriter
    );
  }
  f = message.getAvailableFeatures();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.AvailableFeatures.serializeBinaryToWriter
    );
  }
  f = message.getDeskAvailableFeatures();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.DeskAvailableFeatures.serializeBinaryToWriter
    );
  }
};


/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.RefreshTokenResponse.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RefreshTokenResponse} returns this
 */
proto.RefreshTokenResponse.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional GraphToken graph_token = 2;
 * @return {?proto.GraphToken}
 */
proto.RefreshTokenResponse.prototype.getGraphToken = function() {
  return /** @type{?proto.GraphToken} */ (
    jspb.Message.getWrapperField(this, proto.GraphToken, 2));
};


/**
 * @param {?proto.GraphToken|undefined} value
 * @return {!proto.RefreshTokenResponse} returns this
*/
proto.RefreshTokenResponse.prototype.setGraphToken = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RefreshTokenResponse} returns this
 */
proto.RefreshTokenResponse.prototype.clearGraphToken = function() {
  return this.setGraphToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RefreshTokenResponse.prototype.hasGraphToken = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GraphError graph_error = 3;
 * @return {?proto.GraphError}
 */
proto.RefreshTokenResponse.prototype.getGraphError = function() {
  return /** @type{?proto.GraphError} */ (
    jspb.Message.getWrapperField(this, proto.GraphError, 3));
};


/**
 * @param {?proto.GraphError|undefined} value
 * @return {!proto.RefreshTokenResponse} returns this
*/
proto.RefreshTokenResponse.prototype.setGraphError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RefreshTokenResponse} returns this
 */
proto.RefreshTokenResponse.prototype.clearGraphError = function() {
  return this.setGraphError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RefreshTokenResponse.prototype.hasGraphError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AvailableFeatures available_features = 4;
 * @return {?proto.AvailableFeatures}
 */
proto.RefreshTokenResponse.prototype.getAvailableFeatures = function() {
  return /** @type{?proto.AvailableFeatures} */ (
    jspb.Message.getWrapperField(this, proto.AvailableFeatures, 4));
};


/**
 * @param {?proto.AvailableFeatures|undefined} value
 * @return {!proto.RefreshTokenResponse} returns this
*/
proto.RefreshTokenResponse.prototype.setAvailableFeatures = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RefreshTokenResponse} returns this
 */
proto.RefreshTokenResponse.prototype.clearAvailableFeatures = function() {
  return this.setAvailableFeatures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RefreshTokenResponse.prototype.hasAvailableFeatures = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DeskAvailableFeatures desk_available_features = 5;
 * @return {?proto.DeskAvailableFeatures}
 */
proto.RefreshTokenResponse.prototype.getDeskAvailableFeatures = function() {
  return /** @type{?proto.DeskAvailableFeatures} */ (
    jspb.Message.getWrapperField(this, proto.DeskAvailableFeatures, 5));
};


/**
 * @param {?proto.DeskAvailableFeatures|undefined} value
 * @return {!proto.RefreshTokenResponse} returns this
*/
proto.RefreshTokenResponse.prototype.setDeskAvailableFeatures = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RefreshTokenResponse} returns this
 */
proto.RefreshTokenResponse.prototype.clearDeskAvailableFeatures = function() {
  return this.setDeskAvailableFeatures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RefreshTokenResponse.prototype.hasDeskAvailableFeatures = function() {
  return jspb.Message.getField(this, 5) != null;
};


goog.object.extend(exports, proto);
