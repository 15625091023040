import { EntityState } from '@reduxjs/toolkit'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

import * as MessagePB from '../../proto/message_pb'
import { ResponseAttachment } from '../../proto/message_pb'
import * as RequesterMessagePB from '../../proto/requester_message_pb'
import { RequesterMessageAPI } from '../../proto/requester_message_pb_service'
import { dateFromTimestamp } from '../../utils'
import { deskApi } from '../deskApi'
import {
  requesterReplyEntityAdapter,
  requesterTicketEntityAdapter,
} from './slice'
import type { GetRequesterConversationsArgs } from './types/GetRequesterConversationsArgs'
import type { GetRequesterConverstaionRepliesArgs } from './types/GetRequesterConverstaionRepliesArgs'
import type {
  MessageButtonsItem,
  RequesterMessage,
} from './types/RequesterMessage'
import type { RequesterReplyEntity } from './types/RequesterReplyEntity'
import type {
  RequesterChatFile,
  RequesterTicketEntity,
} from './types/RequesterTicketEntity'
import type { SendRequesterReplyButtonArgs } from './types/SendRequesterReplyButtonArgs'

const serializeKey = (keys: string[]) => keys.join(',')
const emptyTimestamp = new Timestamp().toObject()

const eventButtonIntoRequesterButtonItem = (
  eventButton: MessagePB.Event.Button.AsObject
): MessageButtonsItem => ({
  key: serializeKey([
    ...eventButton.buttonCategoriesList,
    eventButton.label,
    eventButton.data,
  ]),
  label: eventButton.label,
  url: eventButton.url,
  data: eventButton.data,
  type: eventButton.type,
})

const messageEventIntoRequesterMessageEntity = (
  event: MessagePB.Event.AsObject | undefined
): RequesterMessage => {
  if (!event)
    return {
      type: 'text',
      text: '',
    }
  const htmlText = extractHTMLTextFromEvent(event)

  switch (event.type) {
    case 'text': {
      // HTMLが存在しない場合 event.text を返す
      if (!htmlText) {
        return {
          type: 'text',
          text: event.text,
        }
      }

      return {
        type: 'text',
        text: htmlText,
      }
    }
    case 'postback': {
      return {
        type: 'postback',
        text: event.text,
        buttons: event.buttonsList.map(eventButtonIntoRequesterButtonItem),
      }
    }
    case 'confirm': {
      if (!htmlText) {
        return {
          type: 'confirm',
          text: event.text,
          buttons: event.buttonsList.map(eventButtonIntoRequesterButtonItem),
        }
      }

      return {
        type: 'confirm',
        text: htmlText,
        buttons: event.buttonsList.map(eventButtonIntoRequesterButtonItem),
      }
    }
    case 'buttons': {
      if (!htmlText) {
        return {
          type: 'buttons',
          text: event.text,
          buttons: event.buttonsList.map(eventButtonIntoRequesterButtonItem),
        }
      }

      return {
        type: 'buttons',
        text: htmlText,
        buttons: event.buttonsList.map(eventButtonIntoRequesterButtonItem),
      }
    }

    case 'message':
      if (!htmlText) {
        return {
          type: 'text',
          text: event.text,
        }
      }

      return {
        type: 'text',
        text: htmlText,
      }

    case 'image':
      return {
        type: 'image',
        src: event.src,
        to: event.to,
      }

    case 'video':
      return {
        type: 'video',
        src: event.src,
      }

    default:
      return {
        type: 'text',
        text: '',
      }
  }
}

const extractHTMLTextFromEvent = (
  event: MessagePB.Event.AsObject
): string | null => {
  const dataJSON = event.attachmentsList.find(
    (a) => a.type === 'text_html'
  )?.data
  if (!dataJSON) return null
  const parsed = JSON.parse(dataJSON)
  if (!('content' in parsed)) return null
  return parsed.content
}

const conversationsListIntoRequesterTicketEntity = (
  conv: RequesterMessagePB.RequesterConversationWithPartialReplies.AsObject
): RequesterTicketEntity => ({
  id: conv.conversation?.id ?? '',
  message: messageEventIntoRequesterMessageEntity(
    conv.conversation?.message?.event
  ),
  createdAt: dateFromTimestamp(
    conv.conversation?.createdAt ?? emptyTimestamp
  ).toISOString(),
  replyCount: conv.replyCount,
  lastReplyMessage: conv.lastReply
    ? messageResponseIntoRequesterReplyEntity(conv.lastReply)
    : null,
  lastReplyUpdatedAt:
    conv.lastReply?.updatedAt || conv.conversation?.createdAt || emptyTimestamp,
  authorId: conv.conversation?.userId ?? '',
  files:
    (conv.conversation?.message?.event?.attachmentsList
      .map(attachmentIntoRequesterChatFile)
      .filter((a) => !!a) as RequesterChatFile[]) ?? [],
  senderType: conv.conversation?.message?.senderType ?? '',
})

const attachmentIntoRequesterChatFile = (
  attachment: ResponseAttachment.AsObject
): RequesterChatFile | null => {
  if (attachment.type !== 'onedrive') return null
  const data = JSON.parse(attachment.data)
  if (
    !('name' in data) ||
    !('webUrl' in data) ||
    !('mimeType' in data) ||
    !('uniqueId' in data)
  )
    return null
  return {
    id: data.uniqueId,
    name: data.name,
    url: data.webUrl,
    mimeType: data.mimeType,
  }
}

export const messageResponseIntoRequesterReplyEntity = (
  message: RequesterMessagePB.RequesterMessage.AsObject
): RequesterReplyEntity => ({
  id: message.id,
  message: messageEventIntoRequesterMessageEntity(message.event),
  authorId: message.userId,
  ticketId: message.conversationId,
  createdAt: dateFromTimestamp(
    message.createdAt ?? emptyTimestamp
  ).toISOString(),
  files:
    (message.event?.attachmentsList
      .map(attachmentIntoRequesterChatFile)
      .filter((a) => !!a) as RequesterChatFile[]) ?? [],
  senderType: message.senderType,
})

export const requesterTicketListApi = deskApi.injectEndpoints({
  endpoints: (build) => ({
    getRequesterConversations: build.query<
      EntityState<RequesterTicketEntity>,
      GetRequesterConversationsArgs
    >({
      query(arg) {
        const maxUpdatedAt = new Timestamp()
        if (arg.maxUpdatedAt) {
          maxUpdatedAt.fromDate(new Date(arg.maxUpdatedAt))
        }

        const req = new RequesterMessagePB.ListRequesterConversationsRequest()
        req.setMaxUpdatedAt(maxUpdatedAt)

        return {
          service: RequesterMessageAPI.ListRequesterConversations,
          body: req,
        }
      },
      transformResponse(
        response: RequesterMessagePB.ListRequesterConversationsResponse.AsObject
      ) {
        return requesterTicketEntityAdapter.setAll(
          requesterTicketEntityAdapter.getInitialState(),
          response.conversationsList.map(
            conversationsListIntoRequesterTicketEntity
          )
        )
      },
      providesTags: ['RequesterConversations'],
    }),
    getRequesterConversationReplies: build.query<
      EntityState<RequesterReplyEntity>,
      GetRequesterConverstaionRepliesArgs
    >({
      query: (args) => ({
        service: RequesterMessageAPI.ListRequesterMessages,
        body: (() => {
          const req = new RequesterMessagePB.ListRequesterMessagesRequest()
          req.setConversationId(args.ticketId)
          return req
        })(),
      }),
      transformResponse: (
        res: RequesterMessagePB.ListRequesterMessagesResponse.AsObject
      ) => {
        return requesterReplyEntityAdapter.setAll(
          requesterReplyEntityAdapter.getInitialState(),
          res.messagesList.map(messageResponseIntoRequesterReplyEntity)
        )
      },
      providesTags: ['ConversationReplies'],
    }),
    sendRequesterReplyButton: build.mutation<
      void,
      SendRequesterReplyButtonArgs
    >({
      query: (args) => ({
        service: RequesterMessageAPI.CreateRequesterMessage,
        body: (() => {
          const event = new MessagePB.Event()
          event.setText(args.text)
          if (args.type === 'postback') {
            const postback = new MessagePB.Event.Postback()
            postback.setData(args.data ?? '')
            event.setPostback(postback)
            event.setType('postback')
          } else {
            event.setType('message')
          }

          const req = new RequesterMessagePB.CreateRequesterMessageRequest()
          req.setEvent(event)
          req.setConversationId(args.ticketId)

          return req
        })(),
      }),
      invalidatesTags: ['ConversationReplies'],
    }),
    createWelcomeMessage: build.mutation<void, void>({
      query: () => ({
        service: RequesterMessageAPI.CreateWelcomeMessage,
        body: (() => {
          const req = new Empty()
          return req
        })(),
      }),
    }),
  }),
})

export const {
  useGetRequesterConversationsQuery,
  useGetRequesterConversationRepliesQuery,
  useSendRequesterReplyButtonMutation,
  useCreateWelcomeMessageMutation,
} = requesterTicketListApi
