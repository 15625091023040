import { grpc } from '@improbable-eng/grpc-web'
import { GraphError } from '@microsoft/microsoft-graph-client'
import {
  Action,
  ThunkAction,
  ThunkDispatch,
  configureStore,
  isPlainObject,
} from '@reduxjs/toolkit'

import alertsReducer from '../features/alert/alertsSlice'
import authReducer from '../features/auth/authSlice'
import infoReducer from '../features/auth/infoSlice'
import memberReducer from '../features/auth/memberSlice'
import userPhotosReducer from '../features/auth/userPhotosSlice'
import usersReducer from '../features/auth/usersSlice'
import botChannelStorageImagesReducer from '../features/chat/botChannelStorageImagesSlice'
import imagePreviewReducer from '../features/chat/imagePreviewSlice'
import imageTokensReducer from '../features/chat/imageTokensSlice'
import messageAttachmentsReducer from '../features/chat/messageAttachmentsSlice'
import messagesReducer from '../features/chat/messagesSlice'
import pastedImageInfoReducer from '../features/chat/pastedImageInfoSlice'
import thumbnailsReducer from '../features/chat/thumbnailsSlice'
import editorStateReducer from '../features/editor/editorStateSlice'
import editorToolReducer from '../features/editor/editorToolSlice'
import faqLabelReducer from '../features/faq/faqLabelSlice'
import faqReducer from '../features/faq/faqSlice'
import layoutReducer from '../features/layout/layoutSlice'
import defaultTabSettingsReducer from '../features/tabConfig/defaultTabSettingsSlice'
import tabConfigReducer from '../features/tabConfig/tabConfigSlice'
import autoAssignConfigReducer from '../features/ticket/autoAssignConfigSlice'
import botAddedGroupReducer from '../features/ticket/botAddedGroupsSlice'
import channelsReducer from '../features/ticket/channelsSlice'
import csvReducer from '../features/ticket/csvSlice'
import customFieldReducer from '../features/ticket/customFieldSlice'
import customFieldValueReducer from '../features/ticket/customFieldValueSlice'
import groupReducer from '../features/ticket/groupSlice'
import notificationFaqSettingReducer from '../features/ticket/notificationFaqSettingSlice'
import notificationSettingReducer from '../features/ticket/notificationSettingSlice'
import requestersSlice from '../features/ticket/requestersSlice'
import requesterSuggestsSlice from '../features/ticket/requesterSuggestsSlice'
import tabAddedGroupReducer from '../features/ticket/tabAddedGroupSlice'
import tabChannelReducer from '../features/ticket/tabChannelsSlice'
import tenantAppCSVReducer from '../features/ticket/tenantAppCSVSlice'
import ticketReducer from '../features/ticket/ticketSlice'
import * as ActivityMessagePlanEditForm from '../renewfeatures/ActivityMessagePlanEditForm/slice'
import * as ActivityMessagePlanNewForm from '../renewfeatures/ActivityMessagePlanNewForm/slice'
import * as ActivityMessagePlanPreviewReceiverList from '../renewfeatures/ActivityMessagePlanPreviewReceiverList/slice'
import * as ActivityMessagePlansPaginator from '../renewfeatures/ActivityMessagePlansPaginator/slice'
import { reducer as filterSidepanelReducer } from '../renewfeatures/DashboardFilterSidepanel/slice'
import { deskApi } from '../renewfeatures/deskApi'
import { deskOperatorApi } from '../renewfeatures/deskApiOperator'
import { betaGraphApi, graphApi } from '../renewfeatures/graphApi'
import * as RecommendedFaqList from '../renewfeatures/RecommendedFaqList/slice'
import * as RecommendedFaqRedundantIntentList from '../renewfeatures/RecommendedFaqRedundantIntentList/slice'
import * as RecommendedFaqTicket from '../renewfeatures/RecommendedFaqTicket/slice'
import * as RequesterAuthStore from '../renewfeatures/RequesterAuth/slice'
import * as RequesterChatInput from '../renewfeatures/RequesterChatInput/slice'
import * as RequesterReferenceDocumentsPanel from '../renewfeatures/RequesterReferenceDocumentsPanel/slice'
import * as RequesterChatConversationText from '../renewfeatures/RequesterTicketList/features/RequesterChatText/slice'
import * as RequesterTicketList from '../renewfeatures/RequesterTicketList/slice'
import { Services, services } from '../service/services'

/* use middleware
      https://redux-toolkit.js.org/api/getDefaultMiddleware#intended-usage
*/
export const store = configureStore({
  reducer: {
    ticket: ticketReducer,
    messages: messagesReducer,
    messageAttachments: messageAttachmentsReducer,
    imageTokens: imageTokensReducer,
    pastedImageInfo: pastedImageInfoReducer,
    imagePreview: imagePreviewReducer,
    thumbnails: thumbnailsReducer,
    botChannelStorageImages: botChannelStorageImagesReducer,
    auth: authReducer,
    users: usersReducer,
    userPhotos: userPhotosReducer,
    member: memberReducer,
    botAddedGroup: botAddedGroupReducer,
    tabAddedGroup: tabAddedGroupReducer,
    requesters: requestersSlice,
    requesterSuggests: requesterSuggestsSlice,
    group: groupReducer,
    channels: channelsReducer,
    tabChannels: tabChannelReducer,
    info: infoReducer,
    alerts: alertsReducer,
    csvs: csvReducer,
    tenantAppCSV: tenantAppCSVReducer,
    layout: layoutReducer,
    customField: customFieldReducer,
    customFieldValue: customFieldValueReducer,
    tabConfig: tabConfigReducer,
    defaultTabSettings: defaultTabSettingsReducer,
    editorState: editorStateReducer,
    notificationSetting: notificationSettingReducer,
    notificationFaqSetting: notificationFaqSettingReducer,
    editorToolState: editorToolReducer,
    autoAssignConfigState: autoAssignConfigReducer,
    requesterAuth: RequesterAuthStore.reducer,
    requesterTicketList: RequesterTicketList.reducer,
    [`requesterTicketList/requesterChatText`]:
      RequesterChatConversationText.reducer,
    requesterChatInput: RequesterChatInput.reducer,
    filterSidepanel: filterSidepanelReducer,
    recommendedFaqList: RecommendedFaqList.reducer,
    recommendedFaqRedundantIntentList:
      RecommendedFaqRedundantIntentList.reducer,
    recommendedFaqTicket: RecommendedFaqTicket.reducer,
    [deskApi.reducerPath]: deskApi.reducer,
    [deskOperatorApi.reducerPath]: deskOperatorApi.reducer,
    [graphApi.reducerPath]: graphApi.reducer,
    [betaGraphApi.reducerPath]: betaGraphApi.reducer,
    faq: faqReducer,
    faqLabel: faqLabelReducer,
    activityMessagePlansPaginator: ActivityMessagePlansPaginator.reducer,
    activityMessagePlanNewForm: ActivityMessagePlanNewForm.reducer,
    activityMessagePlanEditFrom: ActivityMessagePlanEditForm.reducer,
    activityMessagePlanPreviewReceiverList:
      ActivityMessagePlanPreviewReceiverList.reducer,
    requesterReferenceDocumentsPanel: RequesterReferenceDocumentsPanel.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: { extraArgument: services },
      // https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
      serializableCheck: {
        isSerializable: customizedIsSerializable,
        ignoredPaths: [
          'editorState.editorState',
          'ticket.error',
          'ticket.filters.startDate',
          'ticket.filters.endDate',
          'requesterChatInput.fields',
          'meta.arg',
        ],
        ignoredActions: [
          'tickets/setStartDate',
          'tickets/setEndDate',
          'graphApi/uploadImage/pending',
          'graphApi/uploadImage/fulfilled',
          'deskApi/executeQuery/pending',
          'deskApi/executeQuery/fulfilled',
          'deskApi/executeQuery/rejected',
          'graphApi/executeMutation/pending',
          'graphApi/executeMutation/fulfilled',
          'requesterChatInput/uploadFile/pending',
          'requesterChatInput/uploadFile/fulfilled',
        ],
        ignoredActionPaths: [
          'payload.state',
          'payload.trailers',
          'payload.file',
        ],
      },
    }).concat([
      deskApi.middleware,
      graphApi.middleware,
      betaGraphApi.middleware,
      deskOperatorApi.middleware,
    ]),
})

// reduxにあるIgnoreできるSerializable型はここに追加します。
const ignoreUnSerializableTypes = [GraphError, Date, grpc.Metadata]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function customizedIsSerializable(val: any): boolean {
  const type = typeof val
  if (
    val == null ||
    type === 'string' ||
    type === 'boolean' ||
    type === 'number' ||
    Array.isArray(val) ||
    isPlainObject(val)
  ) {
    return true
  }

  return ignoreUnSerializableTypes.some((t) => val instanceof t)
}

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  Services,
  Action<string>
>
export type ThunkDispatchType = ThunkDispatch<
  RootState,
  Services,
  Action<string>
>
export type GetState = typeof store.getState
export type AsyncThunkConfig<RejectValue = unknown> = {
  state: RootState
  dispatch: ThunkDispatchType
  extra: Services
  rejectValue?: RejectValue
}
