import { ChatItemProps } from '@fluentui/react-northstar'
import { grpc } from '@improbable-eng/grpc-web'
import {
  PayloadAction,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { ReactElement } from 'react'

import { AppThunk, RootState } from '../../app/store'
import { AttachmentType, Tickets } from '../../consts'
import message_pb, { Message } from '../../proto/message_pb'
import message_pb_service from '../../proto/message_pb_service'
import {
  consoleErrorWithAirbrake,
  getDisplayName,
  getWebagentDisplayName,
} from '../../utils'
import { resetTokens } from '../auth/authSlice'
import { customFieldSelector } from '../ticket/customFieldSlice'
import { Ticket } from '../ticket/ticketSlice'
import { createActivityLogItemProps } from './ChatItemActivityLog'
import { createAutoChatItems, createManualChatItems } from './ChatItems'
import { MessageAttachment } from './messageAttachmentsSlice'
import { TextHtmlData } from './types'

const grpcTimeOut = 5000
const fetchInterval = 5000
export const messagesAdapter = createEntityAdapter<message_pb.Message.AsObject>(
  {
    selectId: (message) => message.id,
    sortComparer: (a, b) => {
      if (!a.activity && a.eventsList.length === 0) {
        return -1
      }
      if (!b.activity && b.eventsList.length === 0) {
        return 1
      }
      const aTimestamp = a.activity?.timestamp || a.eventsList[0].timestamp || 0
      const bTimestamp = b.activity?.timestamp || b.eventsList[0].timestamp || 0
      return aTimestamp < bTimestamp ? -1 : 1
    },
  }
)

interface MessagesState {
  loading: boolean
  error: string | null
  readTime: number
  includesActivityLog: boolean
  excludesEvent: boolean
}

export const messagesSlice = createSlice({
  name: 'messages',
  initialState: messagesAdapter.getInitialState<MessagesState>({
    loading: false,
    error: null,
    readTime: 0,
    includesActivityLog: false,
    excludesEvent: false,
  }),
  reducers: {
    createMessageStart(state) {
      state.loading = true
      state.error = null
    },
    createMessageOnMessage(
      state,
      action: PayloadAction<{ message: message_pb.Message.AsObject }>
    ) {
      messagesAdapter.upsertOne(state, action.payload.message)
    },
    grpcOnEnd(
      state,
      action: PayloadAction<{
        code: grpc.Code
        message: string
      }>
    ) {
      const { code, message } = action.payload
      state.loading = false
      if (code === grpc.Code.OK) {
        state.error = null
      } else {
        state.error = message
        consoleErrorWithAirbrake(message)
      }
    },
    getMessagesStart(state) {
      state.loading = true
      state.error = null
    },
    getMessagesOnMessage(
      state,
      action: PayloadAction<{
        messages: Array<Message.AsObject>
        readTime: number | undefined
      }>
    ) {
      if (state.readTime === 0) {
        state.readTime = action.payload.readTime ?? 0
        messagesAdapter.setAll(state, action.payload.messages)
        return
      }
      state.readTime = action.payload.readTime ?? 0
      messagesAdapter.addMany(state, action.payload.messages)
    },
    removeAllMessages(state) {
      state.readTime = 0
    },
    clearAllMessages(state) {
      messagesAdapter.removeAll(state)
    },
    setVisibleActivityLog(state, action: PayloadAction<{ visible: boolean }>) {
      state.includesActivityLog = action.payload.visible
    },
    setVisibleEvent(state, action: PayloadAction<{ visible: boolean }>) {
      state.excludesEvent = !action.payload.visible
    },
  },
})
export const {
  createMessageStart,
  createMessageOnMessage,
  grpcOnEnd,
  getMessagesOnMessage,
  getMessagesStart,
  removeAllMessages,
  setVisibleActivityLog,
  setVisibleEvent,
  clearAllMessages,
} = messagesSlice.actions
export default messagesSlice.reducer

export const messagesSelectors = messagesAdapter.getSelectors(
  (state: RootState) => state.messages
)

let closeTimeOutTimer: (() => void) | null

let closeFetchMessagesTimer: (() => void) | null

let closeGrpcClient: (() => void) | null

export const closeAllTimers = () => {
  return (): void => {
    closeTimeOut()
    closeClient()
    closeFetchMessage()
  }
}

const closeTimeOut = () => {
  if (!closeTimeOutTimer) {
    return
  }
  closeTimeOutTimer()
  closeTimeOutTimer = null
}

const closeClient = () => {
  if (!closeGrpcClient) {
    return
  }
  closeGrpcClient()
  closeGrpcClient = null
}

const closeFetchMessage = () => {
  if (!closeFetchMessagesTimer) {
    return
  }
  closeFetchMessagesTimer()
  closeFetchMessagesTimer = null
}

export const fetchMessages = (ticketId: number): AppThunk => {
  return async (dispatch, getState, { grpcClient }) => {
    const req = new message_pb.ListMessagesRequest()
    req.setTicketId(ticketId)
    // if req.startTime is undefined, it means send readTime with 1970-01-01T00:00:00
    // -3秒にした理由
    // https://pkshatech.atlassian.net/wiki/spaces/WOR/pages/1864859832/Teams+ElasticSearch
    const readTime = new Timestamp()
    // if seconds is 0, it means send readTime with 1970-01-01T00:00:00
    readTime.setSeconds(Math.max(0, getState().messages.readTime - 3))
    req.setStartTime(readTime)
    req.setIncludesActivityLog(getState().messages.includesActivityLog)
    req.setExcludesEvent(getState().messages.excludesEvent)

    const client = grpcClient<
      message_pb.ListMessagesRequest,
      message_pb.ListMessagesResponse
    >(message_pb_service.MessageAPI.ListMessages)
    client.onMessage((message: message_pb.ListMessagesResponse) => {
      dispatch(
        getMessagesOnMessage({
          messages: message.toObject().messagesList,
          readTime: message.getReadTime()?.getSeconds(),
        })
      )
    })

    client.onEnd((code, message) => {
      closeTimeOut()
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      const fetchMessagesId = setTimeout(() => {
        dispatch(fetchMessages(ticketId))
      }, fetchInterval)

      // make closure for fetch messages timer
      closeFetchMessagesTimer = () => {
        clearTimeout(fetchMessagesId)
      }
      dispatch(grpcOnEnd({ code, message }))
    })

    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)

    dispatch(getMessagesStart)
    client.start(meta)
    client.send(req)
    client.finishSend()

    // make closure for closing grpc client after leaving page
    // if client.close() is called, on message, on end call back will not be called event server send messages after timeout expiration
    closeGrpcClient = () => {
      client.close()
    }

    const timeOutId = setTimeout(() => {
      console.info('timeout...')
      client.close()
      // after client close is called, on end will not be called no matter server finished the response or not
      // If timeout occurs, start next fetch message immediately
      dispatch(fetchMessages(ticketId))
    }, grpcTimeOut)

    // make closure for closing timeout timer
    closeTimeOutTimer = () => {
      clearTimeout(timeOutId)
    }
  }
}

export const postMessage =
  (
    plainText: string,
    text: string,
    attachments: MessageAttachment[],
    ticketId: string
  ): AppThunk =>
  async (dispatch, getState, { grpcClient }) => {
    const eventMessage = new message_pb.Event.Message()
    eventMessage.setType('text')
    eventMessage.setText(plainText)

    const event = new message_pb.Event()
    event.setMessage(eventMessage)
    event.setAttachmentsList(
      // 貼り付け画像を除外します
      attachments
        .filter((f) => !f.pastedImage)
        .map((f) => {
          const attachment = new message_pb.ResponseAttachment()
          attachment.setName(f.attachmentData.name)
          attachment.setType(AttachmentType.onedrive)
          const attachmentDataText = JSON.stringify(f.attachmentData)
          attachment.setData(attachmentDataText)
          return attachment
        })
    )

    const htmlAttachment = new message_pb.ResponseAttachment()
    htmlAttachment.setType(AttachmentType.textHTML)
    const textHtmlData: TextHtmlData = {
      content: text,
    }
    htmlAttachment.setData(JSON.stringify(textHtmlData))
    event.addAttachments(htmlAttachment)

    event.setType('message')
    const message = new message_pb.Message()
    message.setEventsList([event])
    message.setTicketId(parseInt(ticketId))

    const req = new message_pb.CreateMessageRequest()
    req.setMessage(message)

    const client = grpcClient<
      message_pb.CreateMessageRequest,
      message_pb.Message
    >(message_pb_service.MessageAPI.CreateMessage)
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)
    client.onMessage((message) => {
      dispatch(createMessageOnMessage({ message: message.toObject() }))
    })
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(grpcOnEnd({ code, message }))
    })
    dispatch(createMessageStart())
    client.start(meta)
    client.send(req)
    client.finishSend()
  }

export const postTyping =
  (ticketId: number): AppThunk =>
  (dispatch, getState, { grpcClient }) => {
    const event = new message_pb.Event()
    event.setType('agent_is_typing')
    const message = new message_pb.Message()
    message.setTicketId(ticketId)
    message.setEventsList([event])
    const req = new message_pb.CreateMessageRequest()
    req.setMessage(message)

    const client = grpcClient<
      message_pb.CreateMessageRequest,
      message_pb.Message
    >(message_pb_service.MessageAPI.CreateMessage)
    const meta = new grpc.Metadata()
    const token = getState().auth.accessToken
    if (token != null) meta.append('authorization', 'bearer ' + token)
    client.onEnd((code, message) => {
      if (code === grpc.Code.Unauthenticated) {
        dispatch(resetTokens())
        return
      }
      dispatch(grpcOnEnd({ code, message }))
    })
    dispatch(createMessageStart())
    client.start(meta)
    client.send(req)
    client.finishSend()
  }

const stateSelector = (state: RootState) => state.messages
export const chatItemsSelector = createSelector(
  [
    (state: RootState) => stateSelector(state).excludesEvent,
    (state: RootState) => state.users.entities,
    (state: RootState) => state.auth.availableFeatures,
    (state: RootState) => state.member.entities,
    (state: RootState) => state.userPhotos.entities,
    (state: RootState) => state.info.teamsAppInstallation,
    messagesSelectors.selectAll,
    (state: RootState) => state.channels.entities,
    customFieldSelector.selectAll,
    (state: RootState, ticket: Ticket) => ticket,
    (state: RootState, ticket: Ticket) => {
      return ticket.requesterType ===
        Tickets.RequesterType.RequesterTypeWebagent
        ? getWebagentDisplayName(ticket.requesterUserId)
        : getDisplayName(state.users.entities[ticket.requesterUserId])
    },
    (
      _,
      __,
      renderAutoChatToggle: (
        showAllAutoMessage: boolean,
        ticketId: number
      ) => ReactElement
    ) => renderAutoChatToggle,
  ],
  (
    excludesEvent,
    userEntities,
    availableFeatures,
    memberEntities,
    userPhotosEntities,
    teamsAppInstallation,
    messages,
    channelsEntities,
    customFields,
    ticket,
    requesterName,
    renderAutoChatToggle
  ): ChatItemProps[] => {
    const requesterPhoto =
      userPhotosEntities[ticket.requesterUserId]?.avatarBlob || ''
    const appName = teamsAppInstallation?.teamsApp?.displayName || ''

    return [
      ...(excludesEvent === false
        ? [
            {
              density: 'compact',
              key: 'pastAutoChatToggleButton',
              message: renderAutoChatToggle(
                ticket.showAllNoteRequestResponse,
                ticket.id
              ),
            } as ChatItemProps,
          ]
        : []),
      ...(!excludesEvent
        ? createAutoChatItems(
            ticket.requestResponses,
            requesterName,
            requesterPhoto,
            appName,
            ticket.showAllNoteRequestResponse
          )
        : []),
      ...createManualChatItems({
        messages,
        userEntities: userEntities,
        memberEntities: memberEntities,
        userPhotosEntities: userPhotosEntities,
        ticket: ticket,
        channelEntities: channelsEntities,
        customFields,
        excludesEvent,
        shouldShowImagePreview:
          availableFeatures?.delegateFileReadWriteAll !== true,
      }),
    ]
  }
)

export const activityLogItemsSelector = createSelector(
  [
    (root: RootState) => root.member.entities,
    (root: RootState) => root.users.entities,
    (root: RootState) => root.channels.entities,
    customFieldSelector.selectAll,
    (_, message: message_pb.Message.AsObject) => message,
    (_, __, index: number) => index,
  ],
  (
    memberEntities,
    userEntities,
    channelEntities,
    customFields,
    message,
    index
  ): createActivityLogItemProps | undefined => {
    return createActivityLogItemProps({
      memberEntities: memberEntities,
      userEntities: userEntities,
      channelEntities: channelEntities,
      customFields: customFields,
      message: message,
      index: index,
    })
  }
)
