import { Box, Flex, Image, Text } from '@fluentui/react-northstar'
import React from 'react'

import styles from './ChatItemImagePlaceholder.module.css'

/*
画像のプレビューを表示しないときに、画像の代わりに表示されるコンポーネント
*/
const ChatItemImagePlaceholder: React.FC<{ name: string; webUrl: string }> = ({
  name,
  webUrl,
}) => {
  const handleClick = () => {
    window.open(webUrl, '_blank')
  }

  return (
    <div className={styles.boxer}>
      <Flex
        gap="gap.small"
        vAlign="center"
        style={{ justifyContent: 'space-around' }}
      >
        <Image src="/assets/img_icon.png" style={{ width: '2.2rem' }} />
        <Text size="small" className={styles.text}>
          {name}
        </Text>
        <Box>
          <button className={styles.button} onClick={handleClick}>
            確認
          </button>
        </Box>
      </Flex>
    </div>
  )
}

export default ChatItemImagePlaceholder
