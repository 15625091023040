import {
  RequesterExchangeTokenRequest,
  RequesterExchangeTokenResponse,
} from '../../proto/requester_auth_pb'
import { RequesterAuthAPI } from '../../proto/requester_auth_pb_service'
import { deskApi } from '../deskApi'
import requiredScope from '../deskApi/lib/graphScopes'
import type { RequesterAuth } from './types/RequesterAuth'

export const extendedApi = deskApi.injectEndpoints({
  endpoints: (builder) => ({
    getRequesterAuth: builder.query<RequesterAuth, string>({
      query: (ssoToken: string) => ({
        service: RequesterAuthAPI.RequesterExchangeToken,
        body: (() => {
          const req = new RequesterExchangeTokenRequest()
          req.setGraphScope(requiredScope.join(' '))
          req.setSsoToken(ssoToken)
          return req
        })(),
        disableAuth: true,
      }),
      transformResponse: (
        baseQueryReturnValue: RequesterExchangeTokenResponse.AsObject
      ) => ({
        accessToken: baseQueryReturnValue.accessToken,
        graphToken: {
          accessToken: baseQueryReturnValue.graphToken?.accessToken ?? '',
          refreshToken: baseQueryReturnValue.graphToken?.refreshToken ?? '',
        },
        availableFeatures: {
          fileAttachment:
            baseQueryReturnValue.availableFeatures?.fileAttachment ?? false,
          delegateFileReadWriteAll:
            baseQueryReturnValue.availableFeatures?.delegateFileReadWriteAll ??
            false,
        },
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useGetRequesterAuthQuery } = extendedApi
