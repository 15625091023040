import { createAsyncThunk } from '@reduxjs/toolkit'
import flow from 'lodash/fp/flow'

import type { RootState, ThunkDispatchType } from '../../../../app/store'
import { deskApi } from '../../../deskApi'
import { MessageKey } from './slice'

export const initMessage = createAsyncThunk<
  { key: MessageKey; message: string },
  { key: MessageKey; message: string },
  {
    state: RootState
  }
>(
  'requesterTicketList/requesterChatText/initMessage',
  async (args, thunkAPI) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(args.message, 'text/html')

    const shouldShowImagePreview =
      thunkAPI.getState().requesterAuth.entity.availableFeatures
        .delegateFileReadWriteAll !== true

    const replaceImagesIntoGraphThumbnail =
      replaceImagesIntoGraphThumbnailCreator(thunkAPI.dispatch)
    const result = await replaceImagesIntoGraphThumbnail(
      doc,
      shouldShowImagePreview
    )

    return {
      key: args.key,
      message: flow(
        wrapImageWithLink,
        textLinkToAnchor,
        (d) => d.body.innerHTML
      )(result),
    }
  }
)
const replaceImagesIntoGraphThumbnailCreator =
  (dispatch: ThunkDispatchType) =>
  async (doc: Document, shouldShowImagePreview: boolean): Promise<Document> => {
    const images = doc.querySelectorAll('img')

    await Promise.all(
      Array.from(images).map(async (image) => {
        image.setAttribute('style', 'height: auto; max-width: 25rem;')

        if (isUploadedByRequester(image)) {
          const thumbnail = await getImageThumbnailUploadedByRequester(
            dispatch,
            image
          )
          image.setAttribute('src', thumbnail)
          return image
        }

        if (isUploadedByOperator(image)) {
          // オペレーターからの貼り付け画像の場合
          if (shouldShowImagePreview) {
            // 画像プレビュー機能ONの場合
            const thumbnail = await getImageThumbnailUploadedByOperator(
              dispatch,
              image
            )
            // ここにあるsrcは元々{desk-domain}/api/v1/images?drive_id={}&item_id={}&token={}の形式
            // 上記な形式は画像プレビューとして使えないため、リアルタイムで撮ってくれたサムネールURLと入れ替えます。
            image.setAttribute('src', thumbnail)
            return image
          } else {
            // 画像プレビュー機能OFFの場合
            // imgタグにdata-web-urlとdata-name属性がある場合、それを使って添付ファイルの要素を作成します。
            if (image.getAttribute('data-web-url') != null) {
              const attachment = createAttachmentElement(
                image.getAttribute('data-name') || '貼り付け画像',
                image.getAttribute('data-web-url') || ''
              )
              image.replaceWith(attachment)
              return attachment
            }

            /* data-web-urlがなく且つプレビュー機能をOFF場合
             * File.ReadWriteAll委任権限に委ねる機能リリースする前に生成した貼り付け画像。この場合は基本的にはないです。
             * 基本的には新規の顧客と既存の顧客でファイル送受信機能OFF のところになります既存の顧客でかつファイル送受信機能ONの顧客にはアプリケーション許可なし版のファイル送受信は展開予定無しです！
             * 一応最全力でプレビュー画像を生成してます。
             */
            const thumbnail = await getImageThumbnailUploadedByOperator(
              dispatch,
              image
            )
            image.setAttribute('src', thumbnail)
            return image
          }
        }
      })
    )

    return doc
  }

// マルチスレッドリクエスター側で画像プレビューを表示しないなら、添付ファイルの要素を作成します。
function createAttachmentElement(
  fileName: string,
  fileUrl: string
): HTMLDivElement {
  const container = document.createElement('div')
  container.style.display = 'flex'
  container.style.flexDirection = 'column'
  container.style.alignItems = 'flex-start'
  container.style.margin = '8px 0'
  container.style.border = '1px solid #242424'
  container.style.borderRadius = '6px'
  container.style.padding = '8px'
  container.style.width = 'max-content'

  const flexDiv = document.createElement('div')

  const richTextDiv = document.createElement('div')

  const richTextInnerDiv = document.createElement('div')

  const pElement = document.createElement('p')

  const innerDiv = document.createElement('div')

  const flexInnerDiv = document.createElement('div')
  flexInnerDiv.style.display = 'flex'
  flexInnerDiv.style.justifyContent = 'left'
  flexInnerDiv.style.alignItems = 'center' // 垂直方向に中央揃え
  flexInnerDiv.style.gap = '8px' // 子要素に少し間隔を追加

  const iconDiv = document.createElement('div')
  iconDiv.style.display = 'flex'
  iconDiv.style.alignItems = 'center'

  const imgElement = document.createElement('img')
  imgElement.setAttribute('src', '/assets/img_icon.png')
  imgElement.setAttribute('alt', 'icon')
  imgElement.style.height = '2.2rem'
  imgElement.classList.add('no-wrap')

  iconDiv.appendChild(imgElement)

  const textP = document.createElement('p')
  textP.textContent = fileName
  textP.style.paddingBottom = '0.3rem'

  const buttonDiv = document.createElement('div')

  const link = document.createElement('a')
  link.href = fileUrl
  link.target = '_blank'
  link.rel = 'noopener noreferrer'

  const button = document.createElement('button')
  button.setAttribute('tabindex', '-1')
  button.textContent = '確認'
  button.style.border = '1px solid #242424'
  button.style.color = '#242424'
  button.style.background = 'transparent'
  button.style.padding = '2px 4px'
  button.style.cursor = 'pointer'
  button.style.borderRadius = '4px'
  button.style.fontSize = '12px'

  link.appendChild(button)
  buttonDiv.appendChild(link)

  flexInnerDiv.appendChild(iconDiv)
  flexInnerDiv.appendChild(textP)
  flexInnerDiv.appendChild(buttonDiv)
  flexInnerDiv.style.display = 'flex'
  flexInnerDiv.style.justifyContent = 'space-around'

  innerDiv.appendChild(flexInnerDiv)
  pElement.appendChild(innerDiv)
  richTextInnerDiv.appendChild(pElement)
  richTextDiv.appendChild(richTextInnerDiv)
  flexDiv.appendChild(richTextDiv)
  container.appendChild(flexDiv)

  return container
}

const isUploadedByRequester = (image: HTMLImageElement): boolean => {
  const driveId = image.getAttribute('data-drive-id')
  if (!driveId) return false
  const itemId = image.getAttribute('data-item-id')
  if (!itemId) return false
  return true
}

const isUploadedByOperator = (image: HTMLImageElement): boolean => {
  const src = image.getAttribute('src')
  if (!src) return false
  if (src.indexOf('group_id=') === -1) return false
  if (src.indexOf('item_id=') === -1) return false
  return true
}

const getImageThumbnailUploadedByRequester = async (
  dispatch: ThunkDispatchType,
  image: HTMLImageElement
): Promise<string> => {
  const driveId = image.getAttribute('data-drive-id')
  if (!driveId) return ''
  const itemId = image.getAttribute('data-item-id')
  if (!itemId) return ''

  const res = await dispatch(
    deskApi.endpoints.getImageThumbnail.initiate(
      {
        driveId,
        itemId,
      },
      { track: false }
    )
  ).unwrap()
  return res.c10000x10000
}

const getImageThumbnailUploadedByOperator = async (
  dispatch: ThunkDispatchType,
  image: HTMLImageElement
): Promise<string> => {
  const src = image.getAttribute('src')
  if (!src) return ''
  const url = new URL(src)
  const searchParams = new URLSearchParams(url.search)

  const groupId = searchParams.get('group_id')
  if (!groupId) return ''
  const itemId = searchParams.get('item_id')
  if (!itemId) return ''

  const res = await dispatch(
    deskApi.endpoints.getImageThumbnail.initiate(
      {
        groupId,
        itemId,
      },
      { track: false }
    )
  ).unwrap()
  return res.c10000x10000
}

const wrapImageWithLink = (doc: Document): Document => {
  const images = doc.querySelectorAll('img')

  Array.from(images).forEach((image) => {
    if (image.classList.contains('no-wrap')) {
      // no-wrapつけてあるのimgタグにははAタグつけません。
      return
    }
    const link = doc.createElement('a')
    link.setAttribute(
      'href',
      image.getAttribute('to') ?? image.getAttribute('src') ?? ''
    )
    link.target = '_blank'
    link.rel = 'noopener noreferrer'
    image.parentNode?.insertBefore(link, image)
    link.appendChild(image)
  })

  return doc
}

export const textLinkToAnchor = (doc: Document): Document => {
  // bedore-webagentのリンクの正規表現に合わせます
  // https://github.com/PKSHATechnology/bedore-webagent/blob/3d647021bd868cc9be9987eb19062d28cb8d2adc/src/lib/message-helper.ts#L93
  const linkRegex = /(https?:\/\/\S+)/gi
  const createAnchor = (match: RegExpMatchArray, doc: Document) => {
    const anchor = doc.createElement('a')
    const url = match[1].startsWith('http') ? match[1] : `http://${match[1]}`
    anchor.href = url
    anchor.textContent = match[1]
    anchor.target = '_blank'
    anchor.rel = 'noopener noreferrer'
    return anchor
  }

  const walkTree = (node: Node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      const textNode = node as Text
      const textContent = textNode.textContent || ''
      const matches = Array.from(textContent.matchAll(linkRegex))
      if (matches.length > 0) {
        const fragment = doc.createDocumentFragment()
        let lastIndex = 0

        matches.forEach((match) => {
          const linkStartIndex = match.index || 0
          const beforeLinkText = textContent.slice(lastIndex, linkStartIndex)
          lastIndex = linkStartIndex + match[0].length
          fragment.append(
            doc.createTextNode(beforeLinkText),
            createAnchor(match, doc)
          )
        })

        fragment.appendChild(doc.createTextNode(textContent.slice(lastIndex)))
        textNode.replaceWith(fragment)
      }
    } else if (
      node.nodeType === Node.ELEMENT_NODE &&
      (node as HTMLElement).tagName !== 'A'
    ) {
      Array.from(node.childNodes).forEach(walkTree)
    }
  }

  walkTree(doc.body)
  return doc
}
