import 'draft-js/dist/Draft.css'

import './ChatInputEditor.module.css'

import {
  FormatIcon,
  PaperclipIcon,
  SendIcon,
} from '@fluentui/react-icons-northstar'
import { Box, Button, Flex, FlexItem } from '@fluentui/react-northstar'
import { ContentState, EditorState, Modifier, SelectionState } from 'draft-js'
import { Options, RenderConfig, stateToHTML } from 'draft-js-export-html'
import React, { DragEventHandler, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { RootState } from '../../app/store'
import { appInsights } from '../../appInsights'
import {
  ChatInputEditorProperties,
  Tickets,
  regExpAutoLink,
} from '../../consts'
import { consoleErrorWithAirbrake } from '../../utils'
import {
  LinkEntityData,
  clearEditorState,
  clearEditorStateDraft,
  createLinkEntityData,
  loadEditorStateDraft,
  resetEditorState,
} from '../editor/editorStateSlice'
import styles from './Chat.module.css'
import { ChatInputAttachments } from './ChatInputAttachments'
import {
  backgroundColorStyleMaps,
  colorStyleMaps,
  fontSizeStyleMaps,
  uploadAttachmentFiles,
} from './ChatInputEditor'
import { ChatInputEditorArea } from './ChatInputEditorArea'
import { ChatInputEditorUtils } from './ChatInputEditorUtils'
import { ImageTokenState, imageTokensSelectors } from './imageTokensSlice'
import {
  MessageAttachment,
  messageAttachmentsSelectors,
  uploadAttachmentProcessesOnClear,
} from './messageAttachmentsSlice'
import { postMessage } from './messagesSlice'
import { NotificationBanner } from './NotificationBanner'
import {
  PastedImageInfoState,
  pastedImageInfoSelectors,
} from './pastedImageInfoSlice'
import { HorizontalLineEntityType, PastedImageEntityType } from './types'

// 「投稿」タブでnotificationBannerが表示されないキリの良い全角文字数
const maxCharactersLength = 12500

const createInlineStyle = (): { [styleName: string]: RenderConfig } => {
  const result: { [styleName: string]: RenderConfig } = {}

  for (const [k, v] of Object.entries(backgroundColorStyleMaps)) {
    result[k] = { style: v }
  }

  for (const [k, v] of Object.entries(colorStyleMaps)) {
    result[k] = { style: v }
  }

  for (const [k, v] of Object.entries(fontSizeStyleMaps)) {
    result[k] = { style: v }
  }

  return result
}

const generateBlockStyle = (
  block: Draft.ContentBlock,
  editorState: Draft.EditorState
): RenderConfig | undefined => {
  const indentType = block
    .getData()
    .get(ChatInputEditorProperties.BlockIndentKey)

  // インテント付きなblockに対して、margin-Leftをつけます
  if (indentType != null) {
    try {
      const indentValue = parseInt(indentType, 10)
      return {
        element: 'p',
        style: {
          'margin-left': `${indentValue * 40}px`,
        },
      }
    } catch {
      consoleErrorWithAirbrake(
        `unable to parseInt for indentType ${indentType}, ${block.getText()}, block: ${JSON.stringify(
          block
        )}`
      )
    }
  }

  // HTMLに変換するときに、（箇条書き2段目頭マークをが白丸に、3段目頭マークを黒四角に）
  if (ChatInputEditorUtils.isListBlock(block)) {
    if (block.getType() === 'unordered-list-item') {
      const depth = block.getDepth()
      if (depth === 1) {
        return {
          style: {
            'list-style-type': 'circle',
          },
        }
      }

      if (depth >= 2) {
        return {
          style: {
            'list-style-type': 'square',
          },
        }
      }
    }
  }

  // simulate blockquote style
  if (ChatInputEditorUtils.isBlockQuoteBlock(block)) {
    let baseStyle = {
      'border-left': '0.4rem solid #d1d1d1',
      'font-size': '1.4rem',
      'background-color': '#f5f5f5',
      color: '#424242',
      display: 'block',
      'margin-block-start': '0em',
      'margin-block-end': '0em',
      'margin-inline-start': '0px',
      'margin-inline-end': '0px',
      padding: '0rem 1.6rem 0rem 1.1rem',
    }

    const contentState = editorState.getCurrentContent()
    const beforeBlock = contentState.getBlockBefore(block.getKey())

    const baseFirstStyle = {
      'margin-top': '0.35rem',
      'padding-top': '0.7rem',
    }

    if (
      beforeBlock == null ||
      ChatInputEditorUtils.isBlockQuoteBlock(beforeBlock) === false
    ) {
      baseStyle = { ...baseStyle, ...baseFirstStyle }
    }

    if (
      beforeBlock != null &&
      ChatInputEditorUtils.isBlockQuoteBlock(beforeBlock) &&
      beforeBlock.getData().get(ChatInputEditorProperties.BlockQuoteLineKey) !==
        block.getData().get(ChatInputEditorProperties.BlockQuoteLineKey)
    ) {
      baseStyle = { ...baseStyle, ...baseFirstStyle }
    }

    const baseLastStyle = {
      'margin-bottom': '0.35rem',
      'padding-bottom': '0.7rem',
    }

    const afterBlock = contentState.getBlockAfter(block.getKey())
    if (
      afterBlock == null ||
      ChatInputEditorUtils.isBlockQuoteBlock(afterBlock) === false
    ) {
      baseStyle = { ...baseStyle, ...baseLastStyle }
    }

    if (
      afterBlock != null &&
      ChatInputEditorUtils.isBlockQuoteBlock(afterBlock) &&
      afterBlock.getData().get(ChatInputEditorProperties.BlockQuoteLineKey) !==
        block.getData().get(ChatInputEditorProperties.BlockQuoteLineKey)
    ) {
      baseStyle = { ...baseStyle, ...baseLastStyle }
    }

    return {
      style: baseStyle,
    }
  }
}

// 送信ボタンを押すときに、draftJsの中身をhtmlに変換します、変換する時に、タグの属性とスタイルのフィルタリングとして、optionsをつけます
const generateStateToHTMLOptions = (
  editorState: EditorState,
  imageTokenStates: ImageTokenState[],
  pastedImageInfoStates: PastedImageInfoState[],
  groupId: string,
  messageAttachments: MessageAttachment[]
): Options => {
  return {
    // blockのスタイルをどのスタイルに変換するかを定義します
    blockStyleFn: (block) => generateBlockStyle(block, editorState),

    // blockの中のcharactorをどのスタイルに変換するかを定義します
    inlineStyles: createInlineStyle(),

    // https://github.com/sstur/draft-js-utils/tree/master/packages/draft-js-export-html#blockrenderers
    // return a string to render this block yourself, or return nothing (null or undefined) to defer to the default renderer.
    blockRenderers: {
      // draft-jsのtypingに合わせてts-ignore必須なので、eslintもignoreする
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      atomic: (block) => {
        const entityKey = block.getEntityAt(0)
        if (entityKey == null) {
          return
        }

        const entity = editorState.getCurrentContent().getEntity(entityKey)

        if (entity.getType() == HorizontalLineEntityType) {
          return `<hr/>`
        }

        if (entity.getType() !== PastedImageEntityType) {
          return
        }

        const { id } = entity.getData()
        const data = imageTokenStates.find((i) => i.id === id)
        if (data == null) {
          return
        }
        const { itemId, token } = data
        if (itemId == null) {
          return
        }

        if (token == null) {
          return
        }

        const pastedimageInfo = pastedImageInfoStates.find((i) => i.id === id)

        if (pastedimageInfo == null) {
          return
        }

        const { webUrl, name, size } = pastedimageInfo
        if (webUrl == null) {
          return
        }

        // 入力欄にある貼り付け画像はimgタグとして存在しています。
        // そのimgタグのsrcはサムネールURLになっています。
        // これから入力欄にある内容をリクエスター側に見せるため、サムネールURLを利用できません（有効期限があるため）。
        // そのため、サムネールURLをdeskのendpointに変換します。
        const src = operatorPastedImageUrl(
          process.env.REACT_APP_SERVER_URL ?? '',
          itemId,
          groupId,
          token
        )

        const uploadFile = messageAttachments.find(
          (u) => u.attachmentData.itemId === itemId
        )
        if (
          uploadFile?.pastedImageSize?.width == null ||
          uploadFile?.pastedImageSize?.height == null
        ) {
          return `<div><img src="${src}" data-name="${name}" data-web-url="${webUrl}" data-size="${size}"/></div>`
        }

        return `<div><img width="${uploadFile.pastedImageSize.width}" height="${uploadFile.pastedImageSize.height}" src="${src}" data-name="${name}" data-web-url="${webUrl}" data-size="${size}"/></div>`
      },
    },
    entityStyleFn: (entity: Draft.EntityInstance) => {
      // CODE entity付きなinline要素に対して、codeタグに変換します
      if (entity.getType() === 'CODE') {
        return {
          element: 'code',
          // こちらのスタイルはms 投稿タブの`何か入力`に合わせた結果です
          style: {
            display: 'inline-block',
            'font-size': 'inherit',
            padding: '0 0.4rem',
            'vertical-align': 'baseline',
            border: 'none',
            'line-height': '2rem',
            'background-color': '#f9f2f4',
            color: '#424242',
            background: '#f5f5f5',
            'border-radius': '0.2rem',
            'margin-top': '0.1rem',
          },
        }
      }

      if (entity.getType() === 'LINK') {
        try {
          const data = entity.getData() as LinkEntityData
          return {
            element: 'a',
            attributes: {
              rel: 'noopener noreferrer',
              target: '_blank',
              href: data.url,
            },
          }
        } catch (e) {
          consoleErrorWithAirbrake(
            'error at converting data into LinkEntityData when converting EditorState into html'
          )
        }
      }
    },
  }
}

// オペレータ側から貼り付け画像をDBに保存する形
// 順番を変えてはいけません。影響範囲は大きいです。
const operatorPastedImageUrl = (
  host: string,
  itemId: string,
  groupId: string,
  token: string
): string => {
  const url = new URL(`${host}/api/v1/images`)
  url.searchParams.append('item_id', itemId)
  url.searchParams.append('group_id', groupId)
  url.searchParams.append('token', token)
  return url.href
}
const ChatInput: React.FC = () => {
  const dispatch = useDispatch()
  const { ticketId } = useParams<{ ticketId: string }>()
  const authState = useSelector((state: RootState) => state.auth)
  const [editorFocus, setEditorFocus] = useState(false)
  const attachments = useSelector(messageAttachmentsSelectors.selectAll)
  const pastedImageTokens = useSelector(imageTokensSelectors.selectAll)
  const pastedImageInfos = useSelector(pastedImageInfoSelectors.selectAll)
  const ticketState = useSelector((state: RootState) => state.ticket)
  const ticket = ticketState.entities[ticketId]
  const groupId = authState.context?.team?.groupId
  const channelId = authState.context?.channel?.id
  const channelRelativeUrl = authState.context?.channel?.relativeUrl
  const usersState = useSelector((state: RootState) => state.users)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [styleEdit, setStyleEdit] = useState(false)
  const editorState = useSelector(
    (state: RootState) => state.editorState
  ).editorState
  // 下書きがあれば、下書きを表示します
  useEffect(() => {
    const meId = usersState.meId
    if (meId != null) {
      dispatch(loadEditorStateDraft(ticketId, meId))
    }
  }, [dispatch, ticketId, usersState.meId])

  useEffect(() => {
    // 画面から出る時には、draftJSのstateをクリアします。そうしないと、それぞれのチケット詳細画面は同じdraftJSのstateを使ってしまいます。
    return () => {
      dispatch(clearEditorState())
    }
  }, [dispatch])

  // 送信ボタンを押した時の挙動。
  const onClick = () => {
    // groupIdが取得できていることが前提です
    if (!groupId) {
      consoleErrorWithAirbrake(`groupId is empty when at onClick for uploading`)
      return
    }

    // 添付画像のアップロードが一つでも完成できていない状態なら、送信しません
    if (!attachments.every((f) => f.progress === 100)) {
      return
    }

    let currentContent = editorState.getCurrentContent()
    let blockMap = currentContent.getBlockMap()
    const blocks = currentContent.getBlocksAsArray()
    const trimmedSelections: SelectionState[] = []
    const linkElements: {
      selection: SelectionState
      entityKey: string
    }[] = []

    let startBlockIndex = 0
    let endBlockIndex = 0
    // 水平線や貼り付け画像などのatomic-block
    let hasAtomicEntity = false
    for (const [index, block] of blocks.entries()) {
      const currentText = block.getText().trim()
      // atomic-blockが存在するかチェック
      if (block.getType() == 'atomic') {
        hasAtomicEntity = true
      }
      // 行頭(index=0,1,2...)が引用ブロックでない空白行の場合は、文字が入るまでstartBlockIndexを更新します
      if (
        currentText === '' &&
        !ChatInputEditorUtils.isBlockQuoteBlock(block) &&
        !ChatInputEditorUtils.isListBlock(block) &&
        index <= startBlockIndex
      ) {
        startBlockIndex = Math.min(index + 1, blocks.length - 1)
      }
      // 空白行でない最後のブロックをendBlockIndexにします
      if (
        currentText !== '' ||
        ChatInputEditorUtils.isBlockQuoteBlock(block) ||
        ChatInputEditorUtils.isListBlock(block)
      ) {
        endBlockIndex = index
      }
    }
    // 水平線か貼り付け画像などatomic-blockがメッセージ中にある場合は、行頭と行末をデフォルトにする
    if (hasAtomicEntity) {
      startBlockIndex = 0
      endBlockIndex = blocks.length - 1
    }

    for (const [index, block] of blocks.entries()) {
      const currentText = block.getText()
      // ブロックの行頭と行末は空白行を削除します
      if (index == startBlockIndex || index == endBlockIndex) {
        // 前側の空白
        const frontSpaceRegex = new RegExp('^\\s+')
        const frontSpaceCount =
          currentText.match(frontSpaceRegex)?.[0]?.length ?? 0
        if (frontSpaceCount > 0) {
          trimmedSelections.push(
            new SelectionState({
              anchorKey: block.getKey(),
              focusKey: block.getKey(),
              anchorOffset: 0,
              focusOffset: frontSpaceCount,
            })
          )
        }
        // 後ろ側の空白
        const endSpaceRegex = new RegExp('\\s+$')
        const endSpaceCount = currentText.match(endSpaceRegex)?.[0]?.length ?? 0
        if (endSpaceCount > 0) {
          trimmedSelections.push(
            new SelectionState({
              anchorKey: block.getKey(),
              focusKey: block.getKey(),
              anchorOffset:
                currentText.length - frontSpaceCount - endSpaceCount,
              focusOffset: currentText.length,
            })
          )
        }
      }
      // 本文前後の空白行を削除します
      if (index < startBlockIndex || index > endBlockIndex) {
        blockMap = blockMap.delete(block.getKey())
      }
    }

    currentContent = currentContent.merge({
      blockMap,
    }) as ContentState

    currentContent = trimmedSelections.reduce((acc, selection) => {
      return Modifier.removeRange(acc, selection, 'forward')
    }, currentContent)

    for (const block of blocks) {
      const currentText = block.getText()
      const reg = new RegExp(regExpAutoLink, 'g')
      for (const s of currentText.matchAll(reg)) {
        const data = createLinkEntityData(s[0])
        // createEntityはインスタンスを作るわけではなく、自分を返します。currentContentにentityを登録します。
        const entityKey = currentContent
          .createEntity('LINK', 'IMMUTABLE', data)
          .getLastCreatedEntityKey()
        const startOffset = s.index
        if (typeof startOffset !== 'number') {
          continue
        }

        linkElements.push({
          selection: new SelectionState({
            anchorKey: block.getKey(),
            focusKey: block.getKey(),
            anchorOffset: startOffset,
            focusOffset: startOffset + data.url.length,
          }),
          entityKey: entityKey,
        })
      }
    }

    const finalContentState = linkElements.reduce((c, linkElement) => {
      return Modifier.applyEntity(
        c,
        linkElement.selection,
        linkElement.entityKey
      )
    }, currentContent)

    const e = EditorState.push(editorState, finalContentState, 'apply-entity')

    // https://www.npmjs.com/package/draft-js-export-html#entitystylefn
    // draftの内容をhtmlに変換する時に、タグにある全てな属性を残るわけではなく、imgタグの場合は、src属性はデフォルトで残りますが
    // 今回、カスタマイズで入れたidという属性を残すために、entityStyleFnを定義する必要があります
    const text = stateToHTML(
      e.getCurrentContent(),
      generateStateToHTMLOptions(
        editorState,
        pastedImageTokens,
        pastedImageInfos,
        groupId,
        attachments
      )
    )
    const plainText = e.getCurrentContent().getPlainText()

    // テキストとファイル(添付ファイル、貼り付け画像)両方とも空きの場合は、送信しません
    if (plainText.trim() === '' && attachments.length === 0) return

    // リッチテキストに関する情報を中心にロギングします
    const eventProperties = {
      operator_id: usersState.meId,
      ticket_id: ticketId,
      text: plainText,
      rich_text: text,
      has_attachments: attachments.length > 0,
    }
    appInsights.trackEvent({ name: 'operator_message' }, eventProperties)

    // メッセージをサーバー側に送信します。
    dispatch(postMessage(plainText, text, attachments, ticketId))

    // 添付画像情報をリセットします。
    dispatch(uploadAttachmentProcessesOnClear())

    // LocalStorageに保存する下書きをクリアします
    const meId = usersState.meId
    if (meId != null) {
      dispatch(clearEditorStateDraft(ticketId, meId))
    }

    // 入力欄をリセットします。
    dispatch(resetEditorState())
  }

  // 添付ファイルclipボタンを押して、ファイルを選択した時の挙動
  const onFileChange = (e: React.SyntheticEvent<HTMLElement>) => {
    const target = e.currentTarget as HTMLInputElement
    const files = target.files
    if (files === null) return

    const filesArray = Array(files.length)
      .fill(0)
      .map((v, i) => files[i])

    uploadAttachmentFiles(
      filesArray,
      ticket,
      groupId,
      channelId,
      channelRelativeUrl,
      authState,
      usersState,
      dispatch
    )

    // clear input files so as to upload the same file consistently
    const t = e.target as HTMLInputElement
    t.value = ''
  }

  const onFileInputClick = () => {
    if (!fileInputRef) return
    const target = fileInputRef.current
    if (!target) return
    target.click()
  }

  let disableSend = false

  // 入力文字数制限の判定
  let notificationBannerContent = ''
  let notificationBannerVisible = false
  const currentContent = editorState.getCurrentContent()
  const inputCharacters = currentContent.getPlainText()
  if (inputCharacters.length > maxCharactersLength) {
    notificationBannerContent =
      '送信するには、メッセージを短くする必要があります。'
    notificationBannerVisible = true
    disableSend = true
  }

  const handleOnDrop: DragEventHandler<HTMLDivElement> = (e) => {
    const files = e.dataTransfer.files
    if (files === null) return

    const filesArray = Array(files.length)
      .fill(0)
      .map((v, i) => files[i])

    uploadAttachmentFiles(
      filesArray,
      ticket,
      groupId,
      channelId,
      channelRelativeUrl,
      authState,
      usersState,
      dispatch
    )
  }

  const handleOnDragOver: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    // ドラッグ時のカーソルを変更（Chromeの場合、＋マークが表示される）
    if (e.dataTransfer !== null) {
      e.dataTransfer.dropEffect = 'copy'
    }
  }

  // 初回レンダリング時にファイルのドラッグアンドドロップのデフォルト動作を禁止
  // これによって、誤って指定エリア外にドロップしたときにブラウザの別タブでファイルが開かれてしまう挙動を防ぐ
  // 各イベントの詳細は右URLを参照 -> https://developer.mozilla.org/ja/docs/Web/API/HTMLElement/dragover_event
  useEffect(() => {
    const app = document.getElementById('App') as HTMLElement
    const dropZone = document.getElementById('drop-zone') as HTMLElement

    const dragenter = (ev: DragEvent) => {
      ev.preventDefault()
      dropZone.classList.remove(styles.fileDropZoneHidden)
    }
    window.addEventListener('dragenter', dragenter, false)

    const dragover = (ev: DragEvent) => {
      ev.preventDefault()
      // ドラッグ時のカーソルを変更（Chromeの場合、＋マークが表示されなくなる）
      if (ev.dataTransfer !== null) {
        ev.dataTransfer.dropEffect = 'move'
      }
    }
    window.addEventListener('dragover', dragover, false)

    const drop = (ev: DragEvent) => {
      ev.preventDefault()
      dropZone.classList.add(styles.fileDropZoneHidden)
    }
    window.addEventListener('drop', drop, false)

    const dragleave = (ev: DragEvent) => {
      ev.preventDefault()
      // div#Appの範囲外にドラッグカーソルが移動した場合
      if (!app.contains(ev.relatedTarget as Node)) {
        dropZone.classList.add(styles.fileDropZoneHidden)
      }
    }
    window.addEventListener('dragleave', dragleave, false)

    return () => {
      window.removeEventListener('dragenter', dragenter)
      window.removeEventListener('dragover', dragover)
      window.removeEventListener('drop', drop)
      window.removeEventListener('dragleave', dragleave)
    }
  }, [])

  // NOTE: リクエスタが Webagent の場合は、一部の項目を隠す。
  const hidden =
    ticket?.requesterType === Tickets.RequesterType.RequesterTypeWebagent

  return (
    <Box>
      <NotificationBanner
        content={notificationBannerContent}
        visible={notificationBannerVisible}
      />
      <Box
        className={styles.inputWrapper}
        styles={({ theme: { siteVariables } }) => ({
          backgroundColor: siteVariables.colorScheme.default.background,
          position: 'relative',
        })}
        onDrop={handleOnDrop}
        onDragOver={handleOnDragOver}
      >
        <Box
          id="drop-zone"
          className={`${styles.fileDropZone} ${styles.fileDropZoneHidden}`}
          hidden={hidden}
        >
          <span>ファイルをここにドロップ</span>
        </Box>
        <Box
          className={styles.inputInner}
          styles={({ theme: { siteVariables } }) => ({
            borderBottomColor: editorFocus
              ? siteVariables.colorScheme.brand.borderFocus1
              : siteVariables.colorScheme.brand.borderFocusWithin,
          })}
        >
          <ChatInputEditorArea
            setEditorFocus={setEditorFocus}
            styleEdit={styleEdit}
            onClick={onClick}
            onStyleEditToggle={() => setStyleEdit(!styleEdit)}
          />
          <ChatInputAttachments />
        </Box>
      </Box>
      <Flex>
        <Button
          text
          iconOnly
          icon={<FormatIcon />}
          onClick={() => setStyleEdit(!styleEdit)}
          hidden={hidden}
        />
        {authState.availableFeatures?.fileAttachment && !hidden && (
          <Button
            text
            iconOnly
            icon={<PaperclipIcon />}
            onClick={onFileInputClick}
          />
        )}
        <FlexItem push>
          <Button
            text
            iconOnly
            icon={<SendIcon />}
            onClick={onClick}
            disabled={disableSend}
          />
        </FlexItem>
      </Flex>

      <input
        multiple={true}
        hidden={true}
        ref={fileInputRef}
        type="file"
        onChange={onFileChange}
      />
    </Box>
  )
}

export default ChatInput
