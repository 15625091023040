import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from '../../app/store'
import { CreateTicketRequestAttachmentOnedriveData } from '../deskApi/types/CreateTicketRequestArgs'
import type { FileProp, FilePropStatus } from './components/AttachmentList'
import type { AttachmentItemStatus } from './slice'
import { AttachmentItem } from './slice'

const stateSelector = (state: RootState) => state.requesterChatInput

export const filePropsSelector = createSelector(
  [stateSelector, (_, key: string) => key],
  (state, key): FileProp[] => {
    if (!state.fields[key]) return []
    return state.fields[key].attachments.map<FileProp>((a) => ({
      id: a.id,
      status: statusIntoFilePropStatus(a.status),
      fileName: a.file.name,
      mimeType: a.file.type,
    }))
  }
)

const statusIntoFilePropStatus = (
  status: AttachmentItemStatus
): FilePropStatus => {
  switch (status) {
    case 'pending':
      return 'pending'
    case 'success':
      return 'done'
    case 'error':
      return 'error'
  }
}

export const attachments = createSelector(
  [stateSelector, (_, key: string) => key],
  (state, key) => {
    if (!state.fields[key]) return []
    return state.fields[key].attachments
  }
)

export const message = createSelector(
  [stateSelector, (_, key: string) => key],
  (state, key) => {
    if (!state.fields[key]) return ''
    return state.fields[key].message
  }
)

export const attachmentOnedriveData = createSelector(
  [(state: RootState) => state.requesterChatInput, (_, key: string) => key],
  (state, key) => {
    if (!state.fields[key]) return []
    return state.fields[key].attachments
      .map(attachmentStateIntoMutation)
      .filter((a) => !!a) as CreateTicketRequestAttachmentOnedriveData[]
  }
)

const attachmentStateIntoMutation = (
  attachment: AttachmentItem
): CreateTicketRequestAttachmentOnedriveData | null => {
  if (
    attachment.driveId &&
    attachment.itemId &&
    attachment.shareLink &&
    attachment.url
  ) {
    return {
      name: attachment.file.name,
      mimeType: attachment.file.type,
      size: attachment.file.size,
      driveUrl: attachment.url,
      downloadUrl: attachment.shareLink,
      itemId: attachment.itemId,
      driveId: attachment.driveId,
      uniqueId: attachment.uniqueId,
      webUrl: attachment.shareLink,
    }
  }
  return null
}
