import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { DRAG_DROP_PASTE } from '@lexical/rich-text'
import { $wrapNodeInElement, mergeRegister } from '@lexical/utils'
import {
  $createParagraphNode,
  $insertNodes,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_EDITOR,
} from 'lexical'
import * as React from 'react'

import { consoleErrorWithAirbrake } from '../../../../utils'
import { $createImageNode, ImageNode } from './node'

export type UploadImageResponse = {
  driveId: string
  itemId: string
  src: string
  webUrl: string // 画像のシェアリンク
  name: string //画像の名前
  size: number //画像のサイズ
}
type Props = {
  uploadImage: (file: File) => Promise<UploadImageResponse>
  getShareLink: (driveId: string, itemId: string) => Promise<string>
}
const ImagePlugin: React.FC<Props> = (props) => {
  const [editor] = useLexicalComposerContext()

  React.useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error('ImagePlugin: ImageNode not registered on editor')
    }

    return mergeRegister(
      editor.registerCommand(
        DRAG_DROP_PASTE,
        (files: File[]) => {
          files.forEach(async (file) => {
            if (!file.type.startsWith('image/')) return

            let result: UploadImageResponse
            try {
              result = await props.uploadImage(file)
            } catch (e) {
              consoleErrorWithAirbrake("Couldn't upload image", e)
              return
            }
            /*
            Get sharelinkUrl
            画像のシェアリンクを取得
            */
            let shareLink = ''
            try {
              shareLink = await props.getShareLink(
                result.driveId,
                result.itemId
              )
              result = { ...result, webUrl: shareLink }
            } catch (e) {
              consoleErrorWithAirbrake("Couldn't get share link", e)
              return
            }
            editor.update(() => {
              const imageNode = $createImageNode({
                driveId: result.driveId,
                itemId: result.itemId,
                src: result.src,
                webUrl: shareLink, // 画像のシェアリンク
                name: result.name, // 画像の名前
                size: result.size, // 画像のサイズ
              })
              $insertNodes([imageNode])
              if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
                $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd()
              }
            })
          })
          return true
        },
        COMMAND_PRIORITY_EDITOR
      )
    )
  }, [editor, props])

  return <></>
}

export default ImagePlugin
